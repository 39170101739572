import React, { useState, useEffect  } from 'react';
import Nav from './Nav';
import { FaMedal, FaSearch, FaStar, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import AuthService from '../Auth/AuthService';
import { CircularProgress, Pagination } from '@mui/material';


function AdminLeaderBoard() {
    const [searchTerm, setSearchTerm] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [leaderBoard, setLeaderBoard] = useState([]);
    const [loading, setLoading] = useState(true);  // Loading state
    const [pag, setPag] = useState(null); // Pagination state
    const itemsPerPage = 15;

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1);
    };

    const filteredData = leaderBoard.filter((user) =>
        user.name?.toLowerCase().includes(searchTerm.toLowerCase())
    );


    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        const getLeaderBoard = async () => {
            try {
                setLoading(true); // Set loading state to true while fetching data
                const res = await AuthService.leaderBoard({
                    page:currentPage,
                    search:searchTerm
                });
                console.log("res?.data",res?.data)
               if(res?.data?.success){
                setPag({
                    page:res?.data?.page,
                    per_page:res?.data?.per_page,
                    total:res?.data?.total,
                    total_pages:res?.data?.total_pages
                })
                setLeaderBoard(res?.data?.data);
               }
            } catch (err) {
                console.log(err);
            } finally {
                setLoading(false); // Set loading to false after data is fetched
            }
        };
        getLeaderBoard();
    }, [currentPage,searchTerm]);
console.log("leaderBoard",leaderBoard)

    return (
        <div className='w-full font-poppins pb-5'>
            <Nav />
            <div className='flex flex-col mx-auto'>
                <h1 className='text-purp font-semibold text-2xl md:text-3xl font-poppins text-center mt-5'>
                    BOH LEADERSHIP BOARD
                </h1>
                <p className='text-bluebg text-center tracking-wide mt-3'>
                    View and monitor users rank and score
                </p>
                <div className="flex items-center relative mt-5 justify-center w-full">
                    <div className="relative w-full max-w-xs sm:max-w-md md:max-w-lg">
                        <input
                            type="text"
                            placeholder="Search users by name..."
                            value={searchTerm}
                            onChange={handleSearchChange}
                            className="p-2 bg-lblue border border-gray-300 rounded-full w-full pr-10 pl-4"
                        />
                        <FaSearch className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
                    </div>
                </div>
            </div>

            <div className='overflow-x-auto mt-5 flex justify-center'>
                {loading ? ( // Display loading spinner when fetching data
                    <CircularProgress />
                ) : (
                    <table className='min-w-[300px] max-w-[800px] w-full bg-white rounded-lg'>
                        <thead>
                            <tr className='bg-purp text-white'>
                                <th className='py-2 px-4 border-b'>Rank</th>
                                <th className='py-2 px-4 border-b'>Name</th>
                                <th className='py-2 px-4 border-b'>Total Score</th>
                                <th className='py-2 px-4 border-b'>Number of Link Clicks</th>
                            </tr>
                        </thead>
                        <tbody>
                            {leaderBoard.length > 0 ? (
                                leaderBoard.map((user, index) => (
                                    <tr key={user.id} className="hover:bg-gray-100">
                                        <td className='py-2 px-4 border-b text-center'>
                                            {index + (currentPage - 1) * itemsPerPage === 0 ? (
                                                <FaMedal className='text-yellow-500 text-xl inline' />
                                            ) : index + (currentPage - 1) * itemsPerPage === 1 ? (
                                                <FaMedal className='text-gray-400 text-xl inline' />
                                            ) : index + (currentPage - 1) * itemsPerPage === 2 ? (
                                                <FaMedal className='text-yellow-700 text-xl inline' />
                                            ) : (
                                                index + 1 + (currentPage - 1) * itemsPerPage
                                            )}
                                        </td>
                                        <td className='py-2 px-4 border-b text-center'>
                                            {user.first_name + " " + user?.last_name}
                                        </td>
                                        <td className='py-2 px-4 border-b text-center'>
                                            <FaStar className='text-yellow-500 inline mr-1' /> {user.point || 0} points
                                        </td>
                                        <td className='py-2 px-4 border-b text-center'>
                                            {user.point > 0 ? Math.ceil(user.point / 10) : 0} total clicks
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="4" className="text-center py-4">
                                        No users found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                )}
            </div>

            <div className="flex justify-center mt-4 items-center space-x-4">
              { pag && <Pagination
                    count={pag?.total_pages}
                    page={pag?.page}
                    onChange={handlePageChange}
                    color="primary"
                />}
            </div>
        </div>
    );
}

export default AdminLeaderBoard;
