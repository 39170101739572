import api from "./api";

class VideoService {
    async getAll(data){
        try{
            const res = await api.get('/videos',{
                params: data
            })
            return res
        }catch(err){
            return err
        }
    }
    async upload(data){
        try{
            const res = await api.post('/video',data)
            return res
        }catch(err){
            return err
        }
    }
    async update(data){
        try{
            const res = await api.patch('/video',data)
            return res
        }catch(err){
            return err
        }
    }
    async getVideoByUrl (data){
        try{
            const res = await api.get('/video',{
                params: data
            })
            return res
        }catch(err){

        }
    }
}

const VideoClass = new VideoService();
export default VideoClass;