import React from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const AppointmentTable = ({ searchTerm, selectedStatus, selectedDate, data, currentPage, totalPages, onPageChange }) => {
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  return (
    <div className="overflow-x-auto mt-10 pb-10 font-poppins">
      <div className="max-w-6xl mx-auto">
        <table className="w-full table-auto items-center justify-center">
          <thead className="bg-gray-200 text-gray-500">
            <tr>
              <th className="px-2 py-2 text-center sm:px-4">Name</th>
              <th className="px-2 py-2 text-center sm:px-4">Date</th>
              <th className="px-2 py-2 text-center sm:px-4">Category</th>
             
              <th className="px-2 py-2 text-center sm:px-4">Email Address</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((appointment) => (
              <tr key={appointment.booking.id} className="border-b border-gray-300">
                <td className="px-4 py-4 text-center sm:text-fblue tracking-wider">{appointment?.user?.first_name}</td>
                <td className="px-4 py-4 text-center sm:text-fblue tracking-wider">{new Date(appointment?.user?.created_at).toLocaleDateString()}</td>
                <td className="px-4 py-4 text-center sm:text-fblue tracking-wider">{appointment?.booking?.category}</td>
                {/* <td className="px-4 py-4 text-center sm:tracking-wider">
                  <span className={appointment.status === 'completed' ? 'text-green-500' : 'text-red-500'}>
                    {appointment.status}
                  </span>
                </td> */}
                <td className="px-4 py-4 text-center sm:px-4 text-fblue tracking-wider break-all">{appointment?.user?.email}</td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Pagination Controls */}
        <div className="flex justify-end items-center mt-4">
          <button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            className={`p-2 ${currentPage === 1 ? 'opacity-50' : ''}`}
          >
            <FaChevronLeft />
          </button>

          <div className="flex items-center space-x-2">
            {[...Array(totalPages)].map((_, index) => (
              <button
                key={index}
                onClick={() => onPageChange(index + 1)}
                className={`px-4 py-2 rounded ${currentPage === index + 1 ? 'bg-purple-600 text-white' : 'bg-gray-300 text-gray-700'}`}
              >
                {index + 1}
              </button>
            ))}
          </div>

          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className={`p-2 ${currentPage === totalPages ? 'opacity-50' : ''}`}
          >
            <FaChevronRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default AppointmentTable;
