import React, { useState, useRef, useEffect } from 'react';
import { FaSearch, FaChevronDown, FaYoutube, FaChevronRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import VideoClass from '../Auth/video.class';
import { toast,ToastContainer } from 'react-toastify';
import { CircularProgress } from '@mui/material'; // Import CircularProgress for loading animation
import AuthService from '../Auth/AuthService';
import { truncateString } from '../Auth/auth';
import { Button, Menu, MenuItem } from '@mui/material';
import { getAuthUser } from '../Auth/auth';

function LatestVideo() {
    const [searchTerm, setSearchTerm] = useState(null);
    const [selectedTopic, setSelectedTopic] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [allVideo, setAllVideo] = useState([]);
    const [authUser, setAuthUser] = useState(null)
    const [isLoading, setIsLoading] = useState(false); // Loading state
    const videoRef = useRef(null);
    const dropdownRef = useRef(null);

    useEffect(()=>{
        const getAuthUser = async()=>{
            try{
                const res = await AuthService.authUser()
                console.log("res from aut user",res?.data);
                if(res?.data?.success){
                    console.log("res?.data?.data",res?.data?.data)
                    setAuthUser(res?.data?.data)
                }
            }catch(err){
                console.log(err);
            }
        }
        getAuthUser()
    },[])
    const topics = ['Depression', 'Lust', 'Anger', 'Finances', 'Discipline'];
    useEffect(() => {
        const getAllVideo = async () => {
            setIsLoading(true); // Start loading
            try {
                const res = await VideoClass.getAll({
                    search: searchTerm,
                    category:selectedTopic
                });
                console.log("video class", res?.data?.videos);
                setAllVideo(res?.data?.videos || []); // Ensure it's an empty array if no videos
            } catch (err) {
                console.error(err);
            } finally {
                setIsLoading(false); // Stop loading
            }
        };
        getAllVideo();
    }, [searchTerm, selectedTopic]);

    const handleSearchChange = (e) => {
        setSelectedTopic(null);
        setSearchTerm(e.target.value);
    };

    const handleTopicChange = (topic) => {
        setSearchTerm(null);
        setSelectedTopic(topic);
        setIsDropdownOpen(false);
    };

    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const videoId = 'ohig84P3aSE';

    const loadYouTubeAPI = () => {
        if (!window.YT) {
            const tag = document.createElement('script');
            tag.src = 'https://www.youtube.com/iframe_api';
            const firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        }
    };
   
    useEffect(() => {
        loadYouTubeAPI();

        window.onYouTubeIframeAPIReady = () => {
            videoRef.current = new window.YT.Player('youtube-video', {
                events: {
                    onReady: () => {
                        const videoElement = videoRef.current;

                        videoElement.getIframe().addEventListener('mouseenter', handleMouseEnter);
                        videoElement.getIframe().addEventListener('mouseleave', handleMouseLeave);
                    },
                },
            });
        };

        return () => {
            if (videoRef.current) {
                const videoElement = videoRef.current;
                videoElement.getIframe().removeEventListener('mouseenter', handleMouseEnter);
                videoElement.getIframe().removeEventListener('mouseleave', handleMouseLeave);
            }
        };
    }, []);

    const handleMouseEnter = () => {
        if (videoRef.current && typeof videoRef.current.playVideo === 'function') {
            videoRef.current.playVideo();
        }
    };

    const handleMouseLeave = () => {
        if (videoRef.current && typeof videoRef.current.pauseVideo === 'function') {
            videoRef.current.pauseVideo();
        }
    };

    function copyToClipboard(ref) {
        const baseUrl = process.env.NEXT_PUBLIC_BASE_URL || "https://boh-c5a2.onrender.com";
        const url = authUser?.id ? `${baseUrl}/video/${ref}/${authUser?.id}` :`${baseUrl}/video/${ref}` ; // URL to copy

        navigator.clipboard.writeText(url)
            .then(() => {
                toast.info("URL copied to clipboard!"); // Use toast instead of alert
            })
            .catch(err => {
                console.error("Failed to copy: ", err);
            });
    }
    const isUserLoggedIn = getAuthUser() ? true : false;

const [isButDropdownOpen, setIsButDropdownOpen] = useState(false);
    const [notification, setNotification] = useState('');
    const baseUrl = process.env.NEXT_PUBLIC_BASE_URL || "https://boh-c5a2.onrender.com";
    console.log("authUser",baseUrl)
    const subscriptionLink = isUserLoggedIn && authUser?.id ? `${baseUrl}/subscribe/${authUser?.id}` : 'https://youtube.com/@breathofhopepodcast?si=CGRCtOQgeZ9nwkYn';
    const videoLink = 'https://youtu.be/ohig84P3aSE?si=AYmSvxqhC4EnN89y';
  
    const toggleButDropdown = () => {
      setIsButDropdownOpen(!isButDropdownOpen);
    };
  
    const copyToClipboardBut = (link) => {
      navigator.clipboard.writeText(link)
        .then(() => {
          setNotification('Link copied to clipboard!');
          setTimeout(() => setNotification(''), 2000); // Remove notification after 2 seconds
        })
        .catch(() => {
          setNotification('Failed to copy the link!');
          setTimeout(() => setNotification(''), 2000);
        });
    };
  
    

    return (
        <div className="w-full p-2">
            <h1 className="font-poppins text-2xl md:text-3xl p-3 mt-10 mx-10 md:mx-20">
                Latest Video
            </h1>

            <div className="flex justify-center w-full mt-4">
                <div className="relative w-full h-0 pb-[56.25%] lg:pb-[40%] xl:pb-[35%] max-w-6xl">
                    <iframe
                        id="youtube-video"
                        src={`https://www.youtube.com/embed/${videoId}?enablejsapi=1`}
                        title="YouTube video"
                        frameBorder="0"
                        allow="autoplay; encrypted-media"
                        allowFullScreen
                        className="absolute top-0 left-0 w-full h-full rounded-lg"
                    ></iframe>
                </div>
            </div>

<div className="relative flex justify-center md:justify-end w-full pr-4 md:pr-32 mt-5">
  {/* Share Button */}
  <button
    className="bg-white text-purp px-4 py-2 rounded-lg border border-gray-300 flex items-center space-x-2"
    onClick={toggleButDropdown}
  >
    <span>Share Links</span>
    <FaChevronDown className={`transition-transform duration-200 ${isButDropdownOpen ? 'rotate-180' : ''}`} />
  </button>

  {/* Dropdown */}
  {isButDropdownOpen && (
    <div className="absolute top-full mt-1 bg-white border border-gray-300 rounded-lg shadow-lg w-48">
      <div
        onClick={() => copyToClipboardBut(subscriptionLink)}
        className="p-2 hover:bg-gray-100 cursor-pointer"
      >
        Subscription Link
      </div>
      <hr className="border-gray-300" />
      <div
        onClick={() => copyToClipboardBut(videoLink)}
        className="p-2 hover:bg-gray-100 cursor-pointer"
      >
        Video Link
      </div>
    </div>
  )}

  {/* Notification */}
  {notification && (
    <div className="absolute top-full mt-2 left-0 bg-green-500 text-white text-sm p-2 rounded-lg">
      {notification}
    </div>
  )}
</div>
            
            

            <div className="flex flex-col md:flex-row items-center gap-5 md:gap-10  mt-10 md:mt-20  md:mx-20">
                <h1 className="font-poppins text-2xl md:text-3xl">Other Videos</h1>
                    <div className='flex gap-3 w-full'>
                    <div className="flex items-center w-full relative md:ml-auto">
                    <input
                        type="text"
                        placeholder="Search videos..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="p-2 bg-lblue border border-gray-300 rounded-md w-full md:w-60 pr-10"
                    />
                    <FaSearch className="absolute right-3 text-gray-500" />
                </div>

                <div className="relative w-full md:w-60 w-full" ref={dropdownRef}>
                    <div
                        onClick={toggleDropdown}
                        className="flex items-center p-2 bg-lblue border border-gray-300 rounded-md cursor-pointer"
                        style={{ zIndex: 10 }}
                    >
                        <span className="flex-1">{selectedTopic || 'Filter by topics'}</span>
                        <FaChevronDown
                            className={`transition-transform duration-200 ${isDropdownOpen ? 'transform rotate-180' : ''}`}
                        />
                    </div>
                    {isDropdownOpen && (
                        <div className="absolute top-full left-0 mt-2 bg-white border border-gray-300 rounded-md w-full z-20">
                            {topics.map((topic, index) => (
                                <div
                                    key={index}
                                    onClick={() => handleTopicChange(topic)}
                                    className="p-2 hover:bg-gray-100 cursor-pointer"
                                >
                                    {topic}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                    </div>
              
            </div>

            {/* Loading Animation and Empty State */}
            {isLoading ? (
                <div className="flex justify-center mt-10">
                    <CircularProgress />
                </div>
            ) : allVideo.length === 0 ? (
                <div className="flex justify-center mt-10 text-gray-500">
                    <p>No videos found. Please try a different search or topic.</p>
                </div>
            ) : (
                <div className="max-w-[1240px] mt-10 md:mt-20 grid grid-cols-1 sm:grid-cols-2  md:grid-cols-3 gap-5 p-4 mx-auto">
                {allVideo.map((item, id) => (
                    <div key={id} className="font-poppins flex flex-col  border border-[#00000012] rounded-[12px] p-4">
                        <img src={item.image} alt={item.title} className="h-[300px] object-cover w-full mx-auto rounded-[12px]" />
                        <div onClick={()=>{
                            copyToClipboard(item?.ref)
                        }} class="flex justify-end relative -top-[50px] p-1.5">
                        <img src='/share.svg' />
                        </div>
                        <h1 className="mt-3 mb-3 text-center font-semibold">{item.title}</h1>
                        <p className="text-center mx-auto max-w-sm p-2">{truncateString(item.summary) }</p>
                        <a href={`${item?.link}`} target="_blank" rel="noopener noreferrer">
                            <button className="mx-auto flex mt-3 h-[50px] items-center bg-[#B20606] rounded-[12px] text-white font-semibold py-2 min-w-full max-w-md justify-center text-sm md:py-2 md:px-4 hover:bg-red-700 transition duration-300">
                                <FaYoutube className="mr-2" size={15} />
                                Watch on YouTube
                            </button>
                        </a>
                    </div>
                ))}
            </div>
            )}

            <div className="mt-5 flex mx-auto justify-center items-center gap-2">
                <Link to="/videopage" className="flex items-center gap-2">
                    <h2 className="text-blue2 font-poppins">See More Videos</h2>
                    <FaChevronRight size={12} className="text-blue2" />
                </Link>
            </div>
            <ToastContainer hideProgressBar={true} />
        </div>
    );
}

export default LatestVideo;
