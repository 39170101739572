import React from 'react'
import Navbar from './Navbar'
import Hero from './Hero'
import Figures from './Figures'
import LatestVideo from './LatestVideo'
import BookSession from './BookSession'
import Community from './Community'
import LeaderBoard from './LeaderBoard'
import Footer from './Footer'
import UserNav from './UserNav'

function UserHome() {
  return (
    <div>
        <UserNav />
        <Hero />
        <Figures />
        <LatestVideo />
        <BookSession />
        <Community />
        <LeaderBoard />
        <Footer />
    </div>
  )
}

export default UserHome