import React, { useEffect, useState } from 'react';
import Nav from './Nav';
import { FaCalendarAlt, FaSearch } from 'react-icons/fa';
import AppointmentTable from './AppointmentTable';
import bookingClass from '../Auth/booking.class';

function Appointment() {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [appointments, setAppointments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const getAllApointment = async (page = 1) => {
      try {
        const res = await bookingClass.getBookings({ page, perPage: 10,search:searchTerm });
        console.log("response from the data", res?.data?.data);
        if (res?.data?.success) {
          setAppointments(res?.data?.data);
          setTotalPages(res?.data?.total_pages);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getAllApointment(currentPage);
  }, [currentPage,searchTerm]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className='w-full'>
      <Nav />
      <div className='flex flex-col'>
        <h1 className='text-darkpurp font-poppins text-3xl md:text-4xl tracking-wide mt-5 ml-4 p-2'>Appointments</h1>
        <p className='text-gray-500 ml-6'>Track appointments and view history</p>
      </div>

      <div className="flex justify-center w-full max-w-4xl mx-auto gap-4">
        {/* Search Bar */}
        <div className="relative w-full md:w-1/3">
          <input
            type="text"
            placeholder="Search name..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="p-2 bg-lblue border border-gray-300 rounded-full w-full pr-10"
          />
          <FaSearch className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
        </div>

        {/* Filter by Date Bar */}
        <div className="relative w-full md:w-1/3">
          <input
            type="date"
            value={selectedDate}
            onChange={handleDateChange}
            className="p-2 bg-lblue border border-gray-300 rounded-full w-full pr-10"
          />
        </div>

        {/* Filter by Status Bar */}
     
      </div>

      <AppointmentTable 
        searchTerm={searchTerm} 
        data={appointments} 
        selectedStatus={selectedStatus} 
        selectedDate={selectedDate} 
        currentPage={currentPage} 
        totalPages={totalPages} 
        onPageChange={handlePageChange} 
      />
    </div>
  );
}

export default Appointment;
