import api from './api'
class BookingService {
    async createClass(data){
        try{
            const res = await api.post('/booking',data)
            return res
        }catch(err){
            return err
        }
    }

    async getBookings(data){
        try{
            const res = await api.get('/booking',
                {
                    params:data
                }
            )
            return res
        }catch(err){
            return err
        }
    }
}

const bookingClass = new BookingService();
export default bookingClass;