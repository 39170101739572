import { setCookie,parseCookies,destroyCookie } from "nookies";

// export const setAuthUser = (userData) =>{
  
//     setCookie(null, 'token',userData, {
//         maxAge: 30 * 24 * 60 * 60,
//         path: '/',
//       })
//     return ;
// }

// export const getAuthUser = ()=>{
//     const cookies = parseCookies()
//     //console.log("cookies",cookies)
//     if(cookies?.token){
//         let user = cookies?.token
//         return user
//     } 
// }



export const setAuthUser = (userData) => {
  setCookie(null, 'token', userData, {
      maxAge: 30 * 24 * 60 * 60,
      path: '/',
  });
  setCookie(null, 'first_name', userData.first_name, {
      maxAge: 30 * 24 * 60 * 60,
      path: '/',
  });
  return;
};


export const getAuthUser = () => {
  const cookies = parseCookies();
  if (cookies?.token && cookies?.first_name) {
    return { token: cookies.token, first_name: cookies.first_name };
  }
  return null; // Return null if user is not authenticated
};
export const logOut = ()=>{
    destroyCookie(null, 'token')
    localStorage.removeItem('user')
    window.location.href="/"
    return ;
}

export function truncateString(str) {
    if (str.length > 50) {
      return str.substring(0, 50) + '...';
    } else {
      return str;
    }
  }
