import React from 'react'
import Navbar from './Navbar'
import Hero from './Hero'
import Figures from './Figures'
import LatestVideo from './LatestVideo'
import BookSession from './BookSession'
import Community from './Community'
import NoResult from './NoResult'
import Footer from './Footer'

function Home() {
  const user = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'));
  const isLoggedIn = !!user;
  return (
    <div>
      <Navbar />  
      <Hero />
      <Figures />
      <LatestVideo />
      <Community />
      <NoResult />
      <Footer />
    </div>
  )
}

export default Home