import React, { useState } from 'react';
import Nav from './Nav';
import { FaArrowLeft, FaSave, FaUpload } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import VideoClass from '../Auth/video.class';
import { toast,ToastContainer } from 'react-toastify';

const categories = ['Depression', 'Lust', 'Anger', 'Finances', 'Discipline'];

const UploadVideo = () => {
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    link: Yup.string().url('Invalid URL').required('Link is required'),
    summary: Yup.string()
      .max(1000, 'Summary cannot exceed 1000 characters')
      .required('Summary is required'),
    category: Yup.string().required('Category is required')
  });

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    const data = {
      title: values.title,
      link: values.link,
      summary: values.summary,
      category: values.category,
      image: image,
    };

    try {
      const response = await VideoClass.upload(data);
      console.log('Upload successful:', response.response?.data?.message);
      toast(response?.data?.message || response.response?.data?.message);
      if(response?.data?.success){
        window.location.href ="/";
      }
    } catch (error) {
      console.error('Upload failed:', error);
    } finally {

    }
  };

  return (
    <div className="w-full font-poppins pb-5">
      <Nav />
      <div className="flex items-center p-8 gap-3">
        <Link to='/admin'><FaArrowLeft size={20} /></Link>
        <h1 className="text-bluebg text-xl md:text-2xl">Upload New Video</h1>
      </div>
      <hr className="border-t border-gray-300 w-full mt-2" />

      <Formik
        initialValues={{ title: '', link: '', summary: '', category: '' }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit }) => (
          <Form className="flex flex-col p-4 items-center mt-8" onSubmit={handleSubmit}>
            {/* Video Title Input */}
            <div className="w-full max-w-md lg:max-w-2xl mb-6">
              <label className="block text-gray-700 text-lg mb-2 tracking-wider">Video Title</label>
              <Field
                name="title"
                placeholder="Type here"
                className="w-full p-3 border border-bluebg rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <ErrorMessage name="title" component="div" className="text-red-600" />
            </div>

            {/* Embedded Link Input */}
            <div className="w-full max-w-md lg:max-w-2xl mb-6">
              <label className="block text-gray-700 text-lg mb-2 tracking-wider">Embedded Link</label>
              <Field
                name="link"
                placeholder="Paste link here"
                className="w-full p-3 border border-bluebg rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <ErrorMessage name="link" component="div" className="text-red-600" />
            </div>

            {/* Category Dropdown */}
            <div className="w-full max-w-md lg:max-w-2xl mb-6">
              <label className="block text-gray-700 text-lg mb-2 tracking-wider">Category</label>
              <Field
                as="select"
                name="category"
                className="w-full p-3 border border-bluebg rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="">Select Category</option>
                {categories.map((category, index) => (
                  <option key={index} value={category}>{category}</option>
                ))}
              </Field>
              <ErrorMessage name="category" component="div" className="text-red-600" />
            </div>

            {/* Video Summary Input */}
            <div className="w-full max-w-md lg:max-w-2xl mb-6 relative">
              <label className="block text-gray-700 text-lg mb-2 tracking-wider">Video Summary</label>
              <Field as="textarea"
                name="summary"
                placeholder="Type here"
                className="w-full h-28 p-3 border border-bluebg rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <ErrorMessage name="summary" component="div" className="text-red-600" />
              <p className="absolute bottom-2 right-2 text-gray-400 text-sm">1000 characters limit</p>
            </div>

            {/* Image Upload Input */}
            <div className="w-full max-w-md lg:max-w-2xl mb-6 p-8 border-dotted border-2 border-bluebg rounded-lg flex flex-col items-center justify-center cursor-pointer">
              <FaUpload size={30} className="text-bluebg mb-2" />
              <label htmlFor="image-upload" className="text-bluebg text-lg mb-2 cursor-pointer">
                Click to upload an image
              </label>
              <p className="text-bluebg text-sm">Maximum file size is 10MB</p>
              <input
                id="image-upload"
                type="file"
                accept="image/*"
                className="hidden"
                onChange={handleImageChange}
              />
              {image && <img src={image} alt="Thumbnail" className="mt-4 w-40 h-40 object-cover" />} {/* Thumbnail display */}
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className={`flex items-center bg-bluebg text-white py-2 px-4 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={loading}
            >
              {loading ? (
                <>
                  <span className="loader"></span>
                  <span className="ml-2">Uploading...</span>
                </>
              ) : (
                <>
                  <FaSave className="mr-2" />
                  Upload Video
                </>
              )}
            </button>
          </Form>
        )}
      </Formik>
      <div className='flex justify-center items-center '>
        <p className='text-lg'>Have a Youtube Video Link? You can <a className='text-purp font-bold' href="/uploadvidlink">upload with link</a>.</p>
      </div>
      <ToastContainer/>
    </div>
  );
}

export default UploadVideo;
