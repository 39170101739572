


import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AuthService from '../Auth/AuthService'; // Ensure this service works
import Log from '../Assets/LOGO - 2 2.png';
import Loginimg from '../Assets/Frame Log.png';
import { toast,ToastContainer } from 'react-toastify';

function SignUp() {
  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      confirm_password: '',
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required('First Name is required'),
      last_name: Yup.string().required('Last Name is required'),
      email: Yup.string().email('Invalid email address').required('email is required'),
      password: Yup.string().min(8, 'password must be at least 8 characters long').required('password is required'),
      confirm_password: Yup.string()
        .oneOf([Yup.ref('password'), null], 'passwords must match')
        .required('Confirm password is required'),
    }),
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        const response = await AuthService.signUp(
         values
        );
        toast(response?.data?.message || response?.response?.data?.message);
        console.log("response from reg", response?.response?.data?.message);
        if(response?.data?.success){
         window.location.href="login"
        }else{
          setErrors({ apiError:  response?.data?.message});
        }
  
        // Handle success (e.g., redirect or show a message)
      } catch (error) {
        console.error('Signup failed:', error);
        setErrors({ apiError: 'Signup failed. Please try again later.' });
      }
      setSubmitting(false);
    },
  });

  return (
    <div className='w-full bg-llblue mt-10 pb-10'>
      <div className='max-w-[1240px] grid md:grid-cols-2 gap-5 mx-auto'>
        <div className="flex flex-col items-center bg-white rounded-md mt-10 font-poppins p-4">
          <img src={Log} alt='/img' className='w-' />
          <h1 className="text-fblue text-center text-2xl md:text-4xl mt-3 mb-4">WELCOME!</h1>
          <p className="text-center max-w-md mb-6">We are happy to have you join us!</p>

          <h1 className='text-darkpurp text-center text-xl '>SIGN UP YOUR ACCOUNT</h1>

          <form className="w-full max-w-lg space-y-4 mt-3" onSubmit={formik.handleSubmit}>
            <div className="flex flex-col md:flex-row gap-4">
              <div className="w-full md:w-1/2 flex flex-col">
                <label htmlFor="first_name" className="text-gray-700 mb-1">First Name</label>
                <input
                  id="first_name"
                  type="text"
                  className="p-2 border border-gray-300 rounded-lg"
                  {...formik.getFieldProps('first_name')}
                />
                {formik.touched.first_name && formik.errors.first_name ? (
                  <p className="text-red-500">{formik.errors.first_name}</p>
                ) : null}
              </div>

              <div className="w-full md:w-1/2 flex flex-col">
                <label htmlFor="last_name" className="text-gray-700 mb-1">Last Name</label>
                <input
                  id="last_name"
                  type="text"
                  className="p-2 border border-gray-300 rounded-lg"
                  {...formik.getFieldProps('last_name')}
                />
                {formik.touched.last_name && formik.errors.last_name ? (
                  <p className="text-red-500">{formik.errors.last_name}</p>
                ) : null}
              </div>
            </div>

            <div className="w-full flex flex-col">
              <label htmlFor="email" className="text-gray-700 mb-1 mt-5">email Address</label>
              <input
                id="email"
                type="email"
                className="p-2 border border-gray-300 rounded-lg"
                {...formik.getFieldProps('email')}
              />
              {formik.touched.email && formik.errors.email ? (
                <p className="text-red-500">{formik.errors.email}</p>
              ) : null}

              <label htmlFor="password" className="text-gray-700 mb-1 mt-5">password</label>
              <input
                id="password"
                type="password"
                className="p-2 border border-gray-300 rounded-lg"
                {...formik.getFieldProps('password')}
              />
              {formik.touched.password && formik.errors.password ? (
                <p className="text-red-500">{formik.errors.password}</p>
              ) : null}

              <label htmlFor="confirm_password" className="text-gray-700 mb-1 mt-5">Confirm password</label>
              <input
                id="confirm_password"
                type="password"
                className="p-2 border border-gray-300 rounded-lg"
                {...formik.getFieldProps('confirm_password')}
              />
              {formik.touched.confirm_password && formik.errors.confirm_password ? (
                <p className="text-red-500">{formik.errors.confirm_password}</p>
              ) : null}
            </div>

            {formik.errors.apiError && (
              <p className="text-red-500 text-center">{formik.errors.apiError}</p>
            )}

            <button
              type="submit"
              className="bg-purp text-white w-[250px] justify-center flex mx-auto py-2 px-4 rounded-lg hover:bg-blue-700 transition duration-300 mt-6"
              disabled={formik.isSubmitting}
            >
              {formik.isSubmitting ? 'Signing Up...' : 'Sign Up'}
            </button>
          </form>
          <div className='mt-5 flex gap-2'>
            <p> Have an Account?</p>
            <a href="login" className='text-purp'>Sign In</a>
          </div>
        </div>

        <div className="bg-lgreen p-4 md:pl-6 mx-auto mt-10 md:ml-auto lg:w-max">
          <img src={Loginimg} alt="/img" className="bg-lpurp p-6 md:pl-8 w-[500px] block" />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default SignUp;

