import React, { useState } from 'react';
import { AiOutlineClose, AiOutlineMenu, AiOutlineDown, AiOutlineUp, AiOutlineEdit } from 'react-icons/ai';
import { FaVideo, FaMedal } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Logo from '../Assets/image 2.png';
import logo2 from '../Assets/image 392.png';
import axios from 'axios'; // Import axios
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AuthService from '../Auth/AuthService';
import { Button } from '@mui/material';
import { logOut } from '../Auth/auth';

function Nav() {
  const [nav, setNav] = useState(false);
  const [profileDropdown, setProfileDropdown] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedProfileId, setSelectedProfileId] = useState(1);
  const [profiles, setProfiles] = useState([
    { id: 1, first_name: 'John', last_name: 'Doe', email: 'john.doe@example.com', image: '/path/to/default-profile1.png' },
    { id: 2, first_name: 'Jane', last_name: 'Smith', email: 'jane.smith@example.com', image: '/path/to/default-profile2.png' },
    { id: 3, first_name: 'Alex', last_name: 'Johnson', email: 'alex.johnson@example.com', image: '/path/to/default-profile3.png' },
  ]);
  const [editProfile, setEditProfile] = useState(profiles[0]);

  const handleNav = () => {
    setNav(!nav);
  };

  const handleProfileClick = () => {
    setProfileDropdown(!profileDropdown);
  };

  const handleProfileChange = (id) => {
    const newProfile = profiles.find((profile) => profile.id === id);
    if (newProfile) {
      setSelectedProfileId(id);
      setEditProfile(newProfile);
      setProfileDropdown(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      first_name: editProfile.first_name,
      last_name: editProfile.last_name,
      email: editProfile.email,
      image: null,
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required('First Name is required'),
      last_name: Yup.string().required('Last Name is required'),
      email: Yup.string().email('Invalid email address').required('Email is required'),
    }),
    onSubmit: async (values) => {
      try {
        // Convert the image to base64
        let base64Image = null;
        if (values.image) {
          const reader = new FileReader();
          reader.readAsDataURL(values.image);
          reader.onloadend = async () => {
            base64Image = reader.result;
            await updateProfile({ ...values, image: base64Image });
          };
        } else {
          await updateProfile(values);
        }
      } catch (error) {
        console.error('Error updating user:', error);
      }
    },
  });

  const updateProfile = async (userData) => {
    try{
      const res = await AuthService.updateUser(userData)
      console.log("response",res)
    }catch(err){
      console.log(err)
    }
   // setProfiles(updatedProfiles);
    setEditModalOpen(false);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      formik.setFieldValue('image', file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setEditProfile({ ...editProfile, image: reader.result });
      };
    }
  };

  const selectedProfile = profiles.find((profile) => profile.id === selectedProfileId);

  return (
    <div>
      <nav className='p-3 bg-purp font-poppins text-white shadow md:flex md:items-center md:justify-between'>
        <div className='flex justify-between items-center w-full md:w-1/3'>
          <Link to='/'>
            <span className='text-2xl cursor-pointer'>
              <img className='h-[60px] bg-purp' src={Logo} alt='Logo' />
            </span>
          </Link>

          <span onClick={handleNav} className='cursor-pointer md:hidden block ml-auto'>
            {nav ? <AiOutlineClose size={24} /> : <AiOutlineMenu size={24} />}
          </span>
        </div>

        <ul className='hidden md:flex md:items-center md:justify-center w-full'>
          {/* <li className='mx-4 my-6 md:my-0 text-xl duration-500 hover:text-gray-400'>
            <Link to=''>View uploads</Link>
          </li> */}
          <li className='mx-4 my-6 md:my-0 text-xl duration-500 hover:text-gray-400'>
            <Link to='/adminleaderboard'>View Leadership board</Link>
          </li>
          <li className='mx-4 my-6 md:my-0 text-xl duration-500 hover:text-lightblue'>
            <Link to='/appointmentlist'>Appointment list</Link>
          </li>
          <li className='mx-4 my-6 md:my-0 md:text-xl duration-500 bg-transparent border border-white rounded-lg md:px-6 hover:text-gray-400'>
            <Button sx={{
              color:"#fff"
            }} onClick={()=>{
              logOut()
            }}>Log Out</Button>
          </li>
        </ul>

        

        {/* Mobile menu */}
        <ul
          className={`fixed top-0 left-0 w-2/3 bg-bluebg h-full border-r p-6 border-gray-300 z-50 transform ${nav ? 'translate-x-0 opacity-100' : '-translate-x-full opacity-0'} transition-transform duration-300 ease-in-out md:hidden`}
        >
          <li className='mb-8'>
            <img src={logo2} alt='/img' className='w-24 ' />
          </li>
          {/* <li className='mx-4 my-6 text-xl hover:bg-lightblue hover:text-bluebg border-b-2 border-gray-500 p-2 flex items-center'>
            <FaVideo className='mr-2' />
            <Link to=''>View Uploads</Link>
          </li> */}
          <li className='mx-4 my-6 text-xl hover:bg-lightblue hover:text-bluebg border-b-2 border-gray-500 p-2 flex items-center'>
            <FaMedal className='mr-2' />
            <Link to='/adminleaderboard'>View Leadership Board</Link>
          </li>
          <li className='mx-4 my-6 text-xl hover:bg-lightblue hover:text-bluebg border-b-2 border-gray-500 p-2 flex items-center'>
            <Link to='/appointmentlist'>Appointment List</Link>
          </li>
          <li className='mx-4 my-6 text-xl hover:bg-lightblue hover:text-bluebg border-b-2 border-gray-500 p-2'>
            <Link to='/'>Log Out</Link>
          </li>

          {selectedProfile && (
            <li className='mt-8'>
              <div className='flex items-center space-x-3' onClick={handleProfileClick}>
                <img
                  src={selectedProfile.image}
                  alt='Profile'
                  className='w-10 h-10 rounded-full'
                />
                <span>{`${selectedProfile.first_name} ${selectedProfile.last_name}`}</span>
                <span className='ml-2'>{profileDropdown ? <AiOutlineUp /> : <AiOutlineDown />}</span>
              </div>
              {profileDropdown && (
                <div className='mt-2 bg-white text-black rounded-lg shadow-lg'>
                  {profiles.map((profile) => (
                    <div key={profile.id} className='flex items-center justify-between p-2 hover:bg-gray-100'>
                      <span>{`${profile.first_name} ${profile.last_name}`}</span>
                      <button className='text-blue-500' onClick={() => handleProfileChange(profile.id)}>Switch</button>
                    </div>
                  ))}
                </div>
              )}
            </li>
          )}
        </ul>
      </nav>

      {editModalOpen && (
        <div className='fixed inset-0 flex items-center justify-center z-50'>
          <div className='bg-white p-6 rounded-lg shadow-lg'>
            <h2 className='text-xl mb-4'>Edit Profile</h2>
            <form onSubmit={formik.handleSubmit}>
              <div className='mb-4'>
                <input
                  type='text'
                  name='first_name'
                  placeholder='First Name'
                  value={formik.values.first_name}
                  onChange={formik.handleChange}
                  className={`border p-2 w-full ${formik.errors.first_name ? 'border-red-500' : 'border-gray-300'}`}
                />
                {formik.errors.first_name && <div className='text-red-500'>{formik.errors.first_name}</div>}
              </div>
              <div className='mb-4'>
                <input
                  type='text'
                  name='last_name'
                  placeholder='Last Name'
                  value={formik.values.last_name}
                  onChange={formik.handleChange}
                  className={`border p-2 w-full ${formik.errors.last_name ? 'border-red-500' : 'border-gray-300'}`}
                />
                {formik.errors.last_name && <div className='text-red-500'>{formik.errors.last_name}</div>}
              </div>
              <div className='mb-4'>
                <input
                  type='email'
                  name='email'
                  placeholder='Email'
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  className={`border p-2 w-full ${formik.errors.email ? 'border-red-500' : 'border-gray-300'}`}
                />
                {formik.errors.email && <div className='text-red-500'>{formik.errors.email}</div>}
              </div>
              <div className='mb-4'>
                <input
                  type='file'
                  accept='image/*'
                  onChange={handleImageChange}
                />
              </div>
              <button type='submit' className='bg-blue-500 text-white px-4 py-2 rounded'>
                Save Changes
              </button>
              <button type='button' className='ml-4 bg-gray-500 text-white px-4 py-2 rounded' onClick={() => setEditModalOpen(false)}>
                Cancel
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default Nav;
