import api from "./api";


class AuthService {
  
  // Login Method
  async login(data) {
    try{
      const res = await api.post('/login', data);
      return res
    }catch(err){
      return err
    }
  }

  // Signup Method
  async signUp(data) {
    try{
      const res = await api.post('/register', data);
      return res
    }catch(err){
      return err
    }
   
  }

  async authUser(){
    try{
      const res = await api.get('/user')
      return res
    }catch(err){
      return err
    }
  }
  async updateUser(data){
    try{
      const res = await api.patch('/user',data)
      return res
    }catch(err){
      return err
    }
  }
  async leaderBoard(data){
    try{
      const res = await api.get('/leaderboard',{
        params:data
      })
      return res
    }catch(err){
      return err
    }
  }
}

export default new AuthService();
