import Tears from '../Assets/Tears.png'
import Tears2 from '../Assets/home page image.png'
import Tears4 from '../Assets/image 392.png'
import Tears5 from '../Assets/Frame 1618869563.png'
import Tear3 from '../Assets/image 391.png'


const UploadData=[
    {
        id:1,
        image:Tears,
        name:'Depressed and Finding God',
        text:'Have you ever struggled with depression and emptiness? You have it all  but nothing seems to fill the void within you. Tune in today as...'
    },
    {
        id:2,
        image:Tears,
        name:'Depressed and Finding God',
        text:'Have you ever struggled with depression and emptiness? You have it all  but nothing seems to fill the void within you. Tune in today as...'
    },
    {
        id:3,
        image:Tears,
        name:'Depressed and Finding God',
        text:'Have you ever struggled with depression and emptiness? You have it all  but nothing seems to fill the void within you. Tune in today as...'
    },
    {
        id:4,
        image:Tears,
        name:'Depressed and Finding God',
        text:'Have you ever struggled with depression and emptiness? You have it all  but nothing seems to fill the void within you. Tune in today as...'
    },
    {
        id:5,
        image:Tears,
        name:'Depressed and Finding God',
        text:'Have you ever struggled with depression and emptiness? You have it all  but nothing seems to fill the void within you. Tune in today as...'
    },
    {
        id:6,
        image:Tears,
        name:'Depressed and Finding God',
        text:'Have you ever struggled with depression and emptiness? You have it all  but nothing seems to fill the void within you. Tune in today as...'
    },
    {
        id:7,
        image:Tears,
        name:'Depressed and Finding God',
        text:'Have you ever struggled with depression and emptiness? You have it all  but nothing seems to fill the void within you. Tune in today as...'
    },
    {
        id:8,
        image:Tears,
        name:'Depressed and Finding God',
        text:'Have you ever struggled with depression and emptiness? You have it all  but nothing seems to fill the void within you. Tune in today as...'
    },
    {
        id:9,
        image:Tears5,
        name:'Depressed and Finding God',
        text:'Have you ever struggled with depression and emptiness? You have it all  but nothing seems to fill the void within you. Tune in today as...'
    },
    {
        id:10,
        image:Tears5,
        name:'Depressed and Finding God',
        text:'Have you ever struggled with depression and emptiness? You have it all  but nothing seems to fill the void within you. Tune in today as...'
    },
    {
        id:11,
        image:Tears5,
        name:'Depressed and Finding God',
        text:'Have you ever struggled with depression and emptiness? You have it all  but nothing seems to fill the void within you. Tune in today as...'
    },
    {
        id:12,
        image:Tears5,
        name:'Depressed and Finding God',
        text:'Have you ever struggled with depression and emptiness? You have it all  but nothing seems to fill the void within you. Tune in today as...'
    },
    {
        id:13,
        image:Tears5,
        name:'Depressed and Finding God',
        text:'Have you ever struggled with depression and emptiness? You have it all  but nothing seems to fill the void within you. Tune in today as...'
    },
    {
        id:14,
        image:Tears5,
        name:'Depressed and Finding God',
        text:'Have you ever struggled with depression and emptiness? You have it all  but nothing seems to fill the void within you. Tune in today as...'
    },
    {
        id:15,
        image:Tears5,
        name:'Depressed and Finding God',
        text:'Have you ever struggled with depression and emptiness? You have it all  but nothing seems to fill the void within you. Tune in today as...'
    },
    {
        id:16,
        image:Tears4,
        name:'Depressed and Finding God',
        text:'Have you ever struggled with depression and emptiness? You have it all  but nothing seems to fill the void within you. Tune in today as...'
    },
    {
        id:17,
        image:Tears4,
        name:'Depressed and Finding God',
        text:'Have you ever struggled with depression and emptiness? You have it all  but nothing seems to fill the void within you. Tune in today as...'
    },
    {
        id:18,
        image:Tears4,
        name:'Depressed and Finding God',
        text:'Have you ever struggled with depression and emptiness? You have it all  but nothing seems to fill the void within you. Tune in today as...'
    },
    {
        id:19,
        image:Tears4,
        name:'Depressed and Finding God',
        text:'Have you ever struggled with depression and emptiness? You have it all  but nothing seems to fill the void within you. Tune in today as...'
    },
    {
        id:20,
        image:Tears4,
        name:'Depressed and Finding God',
        text:'Have you ever struggled with depression and emptiness? You have it all  but nothing seems to fill the void within you. Tune in today as...'
    },
    {
        id:21,
        image:Tears4,
        name:'Depressed and Finding God',
        text:'Have you ever struggled with depression and emptiness? You have it all  but nothing seems to fill the void within you. Tune in today as...'
    },
    {
        id:22,
        image:Tears4,
        name:'Depressed and Finding God',
        text:'Have you ever struggled with depression and emptiness? You have it all  but nothing seems to fill the void within you. Tune in today as...'
    },
    {
        id:23,
        image:Tear3,
        name:'Depressed and Finding God',
        text:'Have you ever struggled with depression and emptiness? You have it all  but nothing seems to fill the void within you. Tune in today as...'
    },
    {
        id:24,
        image:Tear3,
        name:'Depressed and Finding God',
        text:'Have you ever struggled with depression and emptiness? You have it all  but nothing seems to fill the void within you. Tune in today as...'
    },
    {
        id:25,
        image:Tear3,
        name:'Depressed and Finding God',
        text:'Have you ever struggled with depression and emptiness? You have it all  but nothing seems to fill the void within you. Tune in today as...'
    },
    {
        id:26,
        image:Tear3,
        name:'Depressed and Finding God',
        text:'Have you ever struggled with depression and emptiness? You have it all  but nothing seems to fill the void within you. Tune in today as...'
    },
    {
        id:27,
        image:Tear3,
        name:'Depressed and Finding God',
        text:'Have you ever struggled with depression and emptiness? You have it all  but nothing seems to fill the void within you. Tune in today as...'
    },
    {
        id:28,
        image:Tear3,
        name:'Depressed and Finding God',
        text:'Have you ever struggled with depression and emptiness? You have it all  but nothing seems to fill the void within you. Tune in today as...'
    },
    {
        id:29,
        image:Tear3,
        name:'Depressed and Finding God',
        text:'Have you ever struggled with depression and emptiness? You have it all  but nothing seems to fill the void within you. Tune in today as...'
    },
    {
        id:30,
        image:Tears2,
        name:'Depressed and Finding God',
        text:'Have you ever struggled with depression and emptiness? You have it all  but nothing seems to fill the void within you. Tune in today as...'
    },
    {
        id:31,
        image:Tears2,
        name:'Depressed and Finding God',
        text:'Have you ever struggled with depression and emptiness? You have it all  but nothing seems to fill the void within you. Tune in today as...'
    },
    {
        id:32,
        image:Tears2,
        name:'Depressed and Finding God',
        text:'Have you ever struggled with depression and emptiness? You have it all  but nothing seems to fill the void within you. Tune in today as...'
    },
    {
        id:33,
        image:Tears2,
        name:'Depressed and Finding God',
        text:'Have you ever struggled with depression and emptiness? You have it all  but nothing seems to fill the void within you. Tune in today as...'
    },
    {
        id:34,
        image:Tears2,
        name:'Depressed and Finding God',
        text:'Have you ever struggled with depression and emptiness? You have it all  but nothing seems to fill the void within you. Tune in today as...'
    },
    {
        id:35,
        image:Tears2,
        name:'Depressed and Finding God',
        text:'Have you ever struggled with depression and emptiness? You have it all  but nothing seems to fill the void within you. Tune in today as...'
    },
]

export default UploadData